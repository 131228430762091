@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap&subset=latin,cyrillic,arabic");

html {
  height: 100%;
  /*
    Disables pull-to-refresh and overscroll glow effects.
    https://developer.chrome.com/blog/overscroll-behavior/
  */
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
